<template>
  <div class="main">
    <div class="headerWrapper">
      <header class="header">
        <div class="container">
          <div class="header_h1"></div>

          <div class="nav_all wow animate__animated animate__fadeInUp">
            <ul class="nav title-name-box">
              <li class="nav-item title-name" v-for="(item, index) in menu" :key="index">
                <router-link :to="item.path">
                  <span v-html="item.name"></span>
                </router-link>
              </li>
            </ul>
          </div>
          <div class="lines-box" @click="openDrawer">
            <div class="lines"></div>
          </div>
        </div>
      </header>
    </div>

    <el-drawer
        v-model="drawer"
        direction="ttb"
        size="70%"
        title="永富麗建築材料有限公司"
        class="drawer"
    >
      <img class="logo" src="@/assets/img/winfully.png" alt=""/>
      <ul class="drawer-nav">
        <li class="mobile-name-box" v-for="(item, index) in menu" :key="index" @click="closeDrawer">
          <router-link :to="item.path">
            <span v-html="item.name"></span>
          </router-link>
        </li>
      </ul>
    </el-drawer>

    <transition name="fade">
        <router-view></router-view>
    </transition>
  </div>
  <footerAll></footerAll>
</template>

<script>
import { useRouter } from "vue-router";
import footerAll from '@/components/footerAll.vue'
export default {
  data() {
    return {
      backgroundImage: "http://qy.winfully.cn/static/img/1-1.png",
      menu: [],
      timer: "",
      value: 0,
      transitionName: 'slide-right', // 初始过渡动画方式
      drawer: false,
    };
  },
  watch: {
  $route (to, from) {
        // 切换动画
        // 页面之间的跳转一般由路由中的level来实现
        let level = this.$route.meta.level // 监听路由的层级
        if (level === 1) {
          this.transitionName = 'slide-right'
        } else {
          this.transitionName = 'slide-left'
        }
      }
    },
  created() {
    this.timer = setInterval(this.valChange, 2000);
    const router = useRouter();
    this.menu = router.options.routes.splice(0,6);
  },
  methods: {
    openDrawer() {
      this.drawer = true;
    },
    closeDrawer() {
      this.drawer = false;
    },
    valChange() {
      const app = this;
      app.value++;
      if (app.value > 1 && app.value < 3) {
        app.backgroundImage = "http://qy.winfully.cn/static/img/1-1.png";
      } else {
        app.backgroundImage = "http://qy.winfully.cn/static/img/1-2.png";
      }

      if (this.value > 5) {
        this.value = 0;
      }
    },
    handleResize() {
      // 获取根节点
      const html = document.documentElement;
      // 设置基准字体大小
      const baseFontSize = 16;
      // 获取屏幕宽度
      const screenWidth = window.innerWidth;
      // 计算字体大小比例
      const ratio = screenWidth / 375;
      // 计算实际字体大小
      const fontSize = baseFontSize * ratio;
      // 设置根节点字体大小
      html.style.fontSize = `${fontSize}px`;
    }

  },
  mounted() {
    // const screenWidth = window.innerWidth;
    // const html = document.documentElement;
    // if(screenWidth <= 750) {
    //   this.handleResize()
    //   window.addEventListener('resize', this.handleResize)
    // }
  },
  components:{
    footerAll
  }
};
</script>

<style lang="less">
//响应式样式切换，移动端
@media only screen and (min-device-width: 1025px) {
  .lines-box{
    visibility: hidden;
    display: none;
  }
}
@media only screen and (max-device-width: 1024px) {
  .nav_all{
    display: none !important;
  }
}

#app {
  //font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.headerWrapper  {
  position: absolute;
  top: 0;
  left: 0;
}
.el-drawer {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  .logo {
    position: absolute;
    top: 15px;
    left: 35px;
    width: 40px;
    height: 40px;
  }
}
.drawer-nav {
    list-style: none;
    margin-top: -2em;
    padding: 0;
  li {
    margin: 10px;
    border-bottom: 1px solid #d0d0d0;
    padding-bottom: 10px;
    a {
      color: black;
      font-size: 1.1rem;
      font-weight: 600;
      margin-bottom: 10px;
    }
  }
  li:nth-child(1) {
    padding-top: 10px !important;
  }
}
.el-drawer__title {
  margin-left: 3rem !important;
  font-weight: 600;
  line-height: inherit;
  letter-spacing: 0.1em;
  color: black;
  font-size: 1.2rem !important;
}

a {
    text-decoration: none;
  }
.lines-box {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 20px;
  margin-top: 10px;
  right: 20px;
  z-index: 1;
}
.lines::before,
.lines::after,
.lines {
  content: "";
  position: absolute;
  top: 5px;
  right: 0;
  height: 2px;
  width: 35px;
  background-color: #fff;
  z-index: 2;
}

.lines::before {
  left: 0;
  top: 10px;
}

.lines::after {
  top: 20px;
  left: 0;
}

.title-name-box {
  margin-top: 30px !important;
}

.mobile-name-box {
  line-height: 18px;
}

.title-name {
  height: 50px;
  line-height: 22px;
}

.english {
  font-size: 12px !important;
}
</style>
