import {
	createApp
} from 'vue'
import router from './router'
import App from './App.vue'
import './util/rem'
import '@/assets/font/font.css'
import Axios from './util/request';
// 引用UI
import ElementPlus from 'element-plus';
//导入输入样式
import 'element-plus/theme-chalk/index.css';
import './assets/css/commtent.css';
//UI图标
import * as ElIcon from '@element-plus/icons-vue'
//UI语言
import locale from 'element-plus/lib/locale/lang/zh-cn'

import animated from "animate.css";

import { WOW } from "wowjs";



//分离创建出来
const app = createApp(App)
//处理循环图标 不然element 图标不显示
for (let iconName in ElIcon) {
	app.component(iconName, ElIcon[iconName])
}
//使用UI
app.use(ElementPlus, {
	locale
})
app.use(animated)

app.use(Axios)
app.config.globalProperties.$WOW = WOW
app.use(router)

app.mount('#app')
