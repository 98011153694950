<template>
  <main>
    <div class="footer">
      <img src="@/assets/img/winfully.png" alt=""/>
      <div class="right">
        <h1>永富麗建築材料有限公司</h1>
        <h2>Winfully Building Material Co.,LTD</h2>
      </div>
    </div>

    <div class="bottom_nav">
      <div class="left">
        <div class="nav_text">
          <router-link :to="item.path" v-for="item in menu" class="link">
            <h4 class="item">
              {{ item.name }}
            </h4>
          </router-link>
        </div>
      </div>
    </div>

    <div class="footer-list" id="footer-list">
      <img src="@/assets/img/Group1034.png" alt=""/>
    </div>

    <div class="box-footer">
      <div class="item">
        <h3>http://www.masterbathroom.mo</h3>
        <div class="search">
          <h3>Master Bathroom</h3>
          <h3 class="searchLine">微信搜索</h3>
        </div>
      </div>
      <div class="weixin-img">
        <img src="@/assets/img/scan1.jpg" alt="" class="scan"/>
        <img src="@/assets/img/scan2.jpg" alt="" class="scan"/>
      </div>
    </div>

    <div class="bottom">© 2023 永富麗建築材料有限公司， 保留所有權利</div>
  </main>
</template>

<script>
import { WOW } from "wowjs";
import { useRouter } from "vue-router";
export default {
  name: "footerAll",
  data() {
    return {
      isLoading: false,

      menu: [
        {
          path: "/",
          name: "首頁",
        },
        {
          path: "/about",
          name: "集團介紹",
        },
        {
          path: "/project",
          name: "工程項目",
        },
        {
          path: "/catalogue",
          name: "品牌型錄",
        },
        {
          path: "/local",
          name: "本地服務",
        },
        {
          path: "/contactUs",
          name: "聯絡我們",
        },
      ],
    };
  },
  created() {
    const router = useRouter();
    //或直接调用核心，建议不使用这种方式，除非api 模块是请求另一个模块
  },
  methods: {},
  mounted() {
    this.$nextTick(() => {
      new WOW({
        boxClass: "wow", // default
        animateClass: "animated", // default
        offset: 150, // default
        mobile: true, // default
        live: false,

        // live为true时，控制台会提示：MutationObserver is not supported by your browser. & WOW.js cannot detect dom mutations, please call .sync() after loading new content.

        callback: function (box) {
          // console.log("WOW: animating <" + box.tagName.toLowerCase() + ">");
        },
      }).init();
    });
  },
};
</script>


<style lang="less" scoped>
html {
  font-size: 16px
}

@media only screen and (max-width: 768px) {
  .footer {
    .right {
      h1 {
        font-size: 1.2rem !important;
      }

      h2 {
        font-size: 0.9rem !important;
      }
    }
  }

  .bottom_nav {
    .nav_text {
      font-size: 0.8rem;
      gap: 5px;
    }

    .right {
      visibility: hidden;
      display: none;

    }
  }

  .bottom {
    font-size: 0.8rem;
  }

  #footer-list {
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      object-position: center;
      content: url("@/assets/img/Group1034_phone.png");
    }
  }

  .box-footer {
    h3 {
      font-size: 0.6rem;
    }

    .item {
      align-items: center !important;
      width: 60% !important;
    }

    img {
      margin: 0 1rem 0 0 !important;
      width: 4rem !important;
      height: 4rem !important;
    }
  }
}

@media only screen and (max-width: 360px) {
  .box-footer .item h3 {
    font-size: 0.45rem !important;
  }
}

main {
  width: 100%;
  background: #ebebeb;
  margin-top: 30px;
  border-top: 1px solid rgb(56, 53, 52);
  padding-top: 30px;

  .footer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 50px;
      height: 50px;
      margin-right: 20px;
    }

    .right {
      text-align: left;

      h1 {
        font-size: 1.6rem;
        margin: 0
      }

      h2 {
        white-space: nowrap;
        font-size: 1.2rem;
        margin: 0
      }
    }
  }
}

.footer-list {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;

  img {
    height: 60px;
  }

  .item {
    width: 800px;
    display: flex;
    flex-direction: column;

    h3 {
      margin: 0;
    }

    justify-content: center;

    .search {
      h3 {
        border: 1px solid;
        padding: 5px;
        margin: 0;
        line-height: 20px;
        border-radius: 5px;
      }

      display: flex;
      align-items: center;
      padding-left: 50px;
    }
  }
}

.box-footer {
  width: 100%;
  display: flex;
  justify-content: center;

  .item {
    width: 51%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;

    h3 {
      margin: 0;
    }

    .search {
      display: flex;
      align-items: center;
      padding: 10px;
      margin-bottom: 10px;

      h3 {
        max-width: 320px;
        border: 1px solid;
        padding: 5px;
        margin: 5px;
        line-height: 20px;
        border-radius: 5px;
      }
    }
  }

  .weixin-img {
    width: 48%;
    display: flex;

    img {
      width: 12%;
      height: auto;
      object-fit: contain;
      margin: 0 20px 10px 20px;
      border-radius: 15px;
    }
  }
}

.bottom_nav {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;

  .left {
    width: 100%;
    display: flex;
    justify-content: center;

    .nav_text {
      width: 555px;
      justify-content: space-around;
      display: flex;
      font-weight: normal;
    }
  }

  //  .right {
  //    position: absolute;
  //    right: 12vw;
  //    display: flex;
  //    justify-content: space-between;
  //    width: 320px;
  //    bottom: 20px;
  //    .scan {
  //      border-radius: 20px;
  //      height: 120px;
  //      margin: 0px 10px;
  //}
  //  }
}

.bottom {
  display: flex;
  flex-direction: column-reverse;
  background: #302d2c;
  height: 58px;
  line-height: 58px;
  color: white;
  width: 100vw;
  font-weight: bold;
  margin-bottom: 20px;
}

.link {
  text-decoration: none;
  color: rgb(44, 62, 80);
}

</style>
