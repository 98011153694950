import {createRouter, createWebHistory, createWebHashHistory} from "vue-router";

const router = createRouter({
  history: createWebHistory(), //路由模式  createWebHashHistory 或 createWebHistory
  routes: [
    {
      path: "/",
      component: () => import("@/views/index/Index.vue"),
      meta: {
        title: "首頁-永富麗建築材料有限公司",
        noCache: false,
      },
      name: "首頁<br><span class='english'>Home</span>",
      show: true,
    },

    {
      path: "/about",
      component: () => import("@/views/about/Index.vue"),
      meta: {
        title: "集團介紹-永富麗建築材料有限公司",
      },
      name: "集團介紹<br><span class='english'>Company Overview</span>",
      show: true,
    },

    {
      path: "/project",
      component: () => import("@/views/project/Index.vue"),
      meta: {
        title: "工程項目-永富麗建築材料有限公司",
      },
      name: "工程項目<br><span class='english'>Project Reference</span>",
      show: true,
    },

    {
      path: "/catalogue",
      component: () => import("@/views/catalogue/Index.vue"),
      meta: {
        title: "品牌型錄-永富麗建築材料有限公司",
      },
      name: "品牌型錄<br><span class='english'>Brand Catalog</span>",
      show: true,
    },

    {
      path: "/local",
      component: () => import("@/views/local/Index.vue"),
      meta: {
        title: "本地服務-永富麗建築材料有限公司",
      },
      name: "本地服務<br><span class='english'>Local Service</span>",
      show: true,
    },

    {
      path: "/contactUs",
      component: () => import("@/views/contactUs/Index.vue"),
      meta: {
        title: "聯絡我們-永富麗建築材料有限公司",
      },
      name: "聯絡我們<br><span class='english'>Contact Us</span>",
      show: true,
    },
    {
      path: "/retail",
      component: () => import("@/views/brand/Index.vue"),
      meta: {
        title: "零售與批發-永富麗建築材料有限公司",
      },
      name: "零售與批發",
      show: true,
    },
    {
      path: "/history",
      component: () => import("@/views/history/Index.vue"),
      meta: {
        title: "發展歷程-永富麗建築材料有限公司",
      },
      name: "發展歷程",
      show: true,
    },
    {
      path: "/shop",
      component: () => import("@/views/shop/Index.vue"),
      meta: {
        title: "綫上商城-永富麗建築材料有限公司",
      },
      name: "綫上商城",
      show: true,
    },
    {
      path: "/projectDetail",
      component: () => import("@/views/projectDetail/Index.vue"),
      meta: {
        title: "項目详情-永富麗建築材料有限公司",
      },
      name: "项目详情",
      show: true,
    },
    {
      path: "/brandDetail",
      component: () => import("@/views/BrandDetail/Index.vue"),
      meta: {
        title: "品牌詳情-永富麗建築材料有限公司",
      },
      name: "品牌詳情",
      show: true,
    },

    {
      path: "/install",
      component: () => import("@/views/install/Index.vue"),
      meta: {
        title: "安裝服務-永富麗建築材料有限公司",
      },
      name: "安裝服務",
      show: true,
    },
    {
      path: "/sale",
      component: () => import("@/views/sale/Index.vue"),
      meta: {
        title: "售後服務-永富麗建築材料有限公司",
      },
      name: "售後服務",
      show: true,
    },
    {
      path: "/shoppingMall",
      component: () => import("@/views/shoppingMall/Index.vue"),
      meta: {
        title: "線上商城-永富麗建築材料有限公司",
      },
      name: "線上商城",
      show: true,
    },
  ],
  scrollBehavior: () => ({y: 0}),
});

// 全局路由守卫
router.beforeEach((to, from, next) => {
  // console.log(to, from)
  if (to.meta.title) {
    document.title = `${to.meta.title}`;
  }

  // 路由跳转后，让页面回到顶部
  // document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
//   window.pageYOffset = 0;


  next();
});

router.afterEach(() => {
  // console.log(to, from)
  console.log("afterEach");
});

export default router;
