/**  韦.axios封装
 * 请求拦截、相应拦截、错误统一处理
 */
import axios from 'axios';
import { ElNotification , ElMessageBox, ElMessage, ElLoading } from 'element-plus'
import router from '../router/index'
import { isObject } from './util'
 // 创建 axios 实例
 axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

 // console.log('VUE_APP_BASE_API=',process.env)
 const service = axios.create({
   // 服务端api地址
   baseURL: process.env.VUE_APP_BASE_API,
   timeout: 25 * 1000 // 请求超时时间，25秒, 如果上传大文件需要更长
 })
// 接口响应拦截
service.interceptors.request.use(async config => {
            // 让每个请求携带当前用户token
            config.headers.token = sessionStorage.getItem('token')
            return config;
        },
        error => {
            return Promise.error(error);
        })
    // 响应拦截器
service.interceptors.response.use(res => {
	    // console.log('---------请求成功--------',res)
		
		if (!isObject(res)) {
		  const error = { message: '服务端api返回的数据格式不正确' }
		  return Promise.reject(error)
		}

		 if (res.status === 200) {
			   
            return Promise.resolve(res); //进行中        
        }else if(res.status === 500){
			
		 ElNotification.error({ title: "错误，500" })
		}else if(res.status === 401){

		 ElNotification.error({ title: "错误,401" })
			 setTimeout(() => {
			   window.location.reload()
			 }, 1200)
		} else {
            return Promise.reject(res); //失败       
        }
    },
    // 服务器状态码不是200的情况    
    error => {
		 // console.log('---------请求错误--------',error)
        if (error.response.status) {
            switch (error.response.status) {
                // 401: 未登录                
                // 未登录则跳转登录页面，并携带当前页面的路径                
                // 在登录成功后返回当前页面，这一步需要在登录页操作。                
                case 401:
                    break
                    // 403 token过期                
                    // 登录过期对用户进行提示                
                    // 清除本地token和清空vuex中token对象                
                    // 跳转登录页面                
                case 403:
                    // sessionStorage.clear()
                    // router.push('/login')
                    break
                    // 404请求不存在                
                case 404:
                    break;
                    // 其他错误，直接抛出错误提示                
                default:
            }
            return Promise.reject(error.response);
        }
    }
);
	
	const installer = {
	  vm: {},
	  install (Vue) {
	    Vue.use(service)
	  }
	}
export  {
  service as axios,
  
}